@import "./global-variables.scss";
@font-face {
  font-family: ds-digi;
  src: url(DS-DIGI.TTF);
}

body {
  min-width: 400px;
}
.ticket-wrapper {
  background-color: white;
  height: 100vh;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  // text-align: center;
  color: #111111;
  .header {
    .bar-top {
      width: 100%;
      height: 60px;
      background: #330D73;
    }
    .bar-bottom {
      width: 100%;
      height: 12px;
      background: #E77817;
    }
  }
  .sub-header {
    @include display-flex(center, center);
    width: 100%;
    background-color: black;
    .tag-wrapper {
      @include display-flex(center, center, column);
      flex-wrap: wrap;
      width: calc(100% - 20px);
      padding: 15px;
      .clock{
        line-height: 30px;
        color: white;
        // font-family: ds-digi;
        padding: 0 10px;

        span {
          padding-left: 10px;
        }
      }
      .title{
        font-size: 22px;
        line-height: 22px;
        // transform: skew(-20deg);
        background: #FE0600;
        margin: 10px;
        padding: 10px 30px;
        position: relative;
        color: white;
        text-align: center;
        .tag-label {
          white-space: nowrap; 
        }
      }

      .title::before{
        content: '';
        position: absolute;
        top:0;
        right: calc(100% - 1px);
        height: 100%;
        width: 30px;
        background: #FE0600;
        -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      }

      .title::after{
        content: '';
        position: absolute;
        top:0;
        left: calc(100% - 1px);
        height: 100%;
        width: 30px;
        background: #FE0600;
        -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
        clip-path: polygon(100% 0, 0 0, 0 100%);
      }

      .tag {
        font-weight: 600;
        color: #02C609;
        margin: 0 10px;
        .tag-number {
          // font-size: 33px;
          // line-height: 33px;
          // margin: 0 10px;
          width: 100%;
        }
      }
      .broadcast {
        width: 30px;
        margin-left: 10px;
        -webkit-animation: flickerAnimation 1s infinite;
        -moz-animation: flickerAnimation 1s infinite;
        -o-animation: flickerAnimation 1s infinite;
        animation: flickerAnimation 1s infinite;
      }
      .reminder-wrapper {
        width: 100%;
        .reminder {
          @include display-flex(center, center);
          height: 54px;           
          font-size: 34px;
          line-height: 34px;
          text-align: center;
          color: #FFFFFF;

          &.warning {     
            background: #FF0000;  
          }

          &.calling {     
            background: #02C609;      
          }
        }
      }
    }
  }
  .reject-reason{
    font-size: 22px;
    line-height: 22px;
    padding: 10px 0px;
    color: white;
    background-color: black;
    text-align: center;
  }
  .content {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-flow: center;
    background-color: white;
    position: relative;
    .logo {
      width: 220px;
      padding: 20px 0px;
    }
    .name {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }
    .ticket-number {
      font-size: 40px;
    }
    .size {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .remark-wrapper {
      margin: 40px 20px 100px 20px;
      .remark-sentence {
        display: flex;
        color: #FF0000;
        padding-bottom: 15px;
        .starish {
          padding-right: 5px;
        }
      }
      .remark {
        font-size: 15px;
        line-height: 15px;
        // font-weight: 600;

        &.important {
          color: #FF0000;
        }
      }
    }
    .tag-wrapper {
      padding: 20px 0px;
    }
  }
  
  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  // @-o-keyframes flickerAnimation{
  //   0%   { opacity:1; }
  //   50%  { opacity:0; }
  //   100% { opacity:1; }
  // }
  // @-moz-keyframes flickerAnimation{
  //   0%   { opacity:1; }
  //   50%  { opacity:0; }
  //   100% { opacity:1; }
  // }
  // @-webkit-keyframes flickerAnimation{
  //   0%   { opacity:1; }
  //   50%  { opacity:0; }
  //   100% { opacity:1; }
  // }
  
  @media screen and (min-width: 601px) {
    .content {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;
    }

    .clock {
      font-size: 25px;
    }

    .tag {
      @include display-flex(center, center);        
      min-width: 400px;
    }
  }

  @media screen and (max-width: 601px) {    
    .tag-wrapper {
      width: 300px !important;

      .clock{
        font-size: 20px;
      }

      .tag {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .tag-number {
          flex-wrap: wrap;
        }
      }
    }
  }
}
